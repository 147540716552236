import Features from '../components/Features'
import { useRef } from 'react'
import Plans from '../components/Plans'
import Wrap from '../components/Wrap'
import P from '../components/P'
import Rates from '../components/Rates'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import Section from '../components/Section'
import Intro from '../components/Intro'
import Table from '../components/Table'
import Support from '../components/Support'
import Connect from '../components/Connect'
import Heading from '../components/Heading'

export default function Subscriptions() {
  const ratesRef = useRef(null)
  const supportRef = useRef(null)
  const tradePartnerRef = useRef(null)

  return (
    <>
      <Section large>
        <Menu />
      </Section>
      <Wrap>
        <Section>
          <Heading center type={1} size={1}>
            Abonnementen
          </Heading>
          <Intro>
            Een transparant overzicht van de verschillen tussen onze
            abonnementen, zonder verborgen kosten.
          </Intro>
        </Section>
        <Section large>
          <Table>
            <Plans />
            <Features
              refs={{
                rates: ratesRef,
                support: supportRef,
                connect: tradePartnerRef,
              }}
            />
          </Table>
        </Section>
        <Section small>
          <Heading center reference={ratesRef} type={3} size={3}>
            Staffels
          </Heading>
        </Section>
        <Section large>
          <Table>
            <Rates />
          </Table>
        </Section>
        <Section small>
          <Heading center reference={supportRef} type={3} size={3}>
            Support kosten
          </Heading>
        </Section>
        <Section large>
          <Table>
            <Support />
          </Table>
        </Section>
        <Section small>
          <Heading auto reference={tradePartnerRef}>
            Koppelen handelspartners
          </Heading>
          <P>
            Afhankelijk van uw abonnement kunt u met zowel interne als externe
            handelspartners koppelen. <em>Interne handelspartners</em> zijn
            partners die als klant EDI-Central afnemen, waardoor de volledige
            communicatie binnen ons systeem blijft.{' '}
            <em>Externe handelspartners</em> zijn partners met een eigen
            voorziening, waarmee een externe koppeling tot stand wordt gebracht.
          </P>
          <P>
            Wilt u met een handelspartner koppelen, maar is deze nog niet bij
            ons platform aangesloten? Zodra u via het EDI-Central webportaal een
            koppelingsverzoek indient zullen wij met de betreffende partner
            contact opnemen om de technische implementatie mogelijkheden te
            bespreken.
          </P>
          <P>
            Nadat de onderliggende communicatie is opgezet, bent u zelf
            verantwoordelijk voor het inhoudelijk versturen en valideren van de
            gewenste berichten van systeem tot systeem. Indien gewenst kunnen
            wij u hier tegen support tarief bij ondersteunen of begeleiden.
          </P>
        </Section>
        <Section large>
          <Table>
            <Connect refs={{ support: supportRef }} />
          </Table>
        </Section>
        <Section>
          <Heading auto type={3} size={3}>
            Berichtstandaarden en vertalingen
          </Heading>
          <P>
            Communiceren u en uw handelspartner in verschillende
            berichtenstandaarden? U kunt dan kostenloos gebruik maken van onze
            vertalingen tussen o.a. de BOU, INSBOU en EDIFACT standaarden.
          </P>
          <P>
            Eventueel benodigde afwijkingen van deze standaarden worden tegen
            support tarief geïmplementeerd. Staat uw berichtenstandaard nog niet
            in ons systeem, neemt u dan contact met ons op.
          </P>
        </Section>
      </Wrap>
      <Footer />
    </>
  )
}
