export default function ScrollLink({ to, children }) {
  return (
    <span
      className="underline text-blue-700 hover:text-blue-800 cursor-pointer"
      onClick={() => {
        if (to.current) {
          to.current.scrollIntoView({ behavior: 'smooth' })
        }
      }}
    >
      {children}
    </span>
  )
}
