import Row from './Row'
import GridHead from './GridHead'
import Grid from './Grid'

export default function Support() {
  return (
    <>
      <Grid whiteBorder>
        <GridHead label="Support type" className="lg:rounded-tl-xl w-1/3" />
        <GridHead label="Kosten" className="lg:rounded-tr-xl w-2/3" />
      </Grid>
      <div className="lg:shadow-xl lg:rounded-xl">
        <Row
          wide
          label="EDI-Central Client installatie"
          options="€ 95 / uur"
          description="Afhankelijk van eventuele maatwerk wensen"
        />
        <Row wide label="Support" options="€ 95 / uur" />
        <Row
          wide
          thin
          label="Ontwikkelen vertaling en maatwerk"
          options="Vertalingen en maatwerk op aanvraag."
        />
      </div>
    </>
  )
}
