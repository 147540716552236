import Row from './Row'
import Grid from './Grid'
import GridHead from './GridHead'

export default function Rates() {
  return (
    <>
      <Grid whiteBorder>
        <GridHead label="Van" className="lg:rounded-tl-xl w-1/3" />
        <GridHead label="Tot" className="w-1/3" />
        <GridHead label="Kosten" className="lg:rounded-tr-xl w-1/3" />
      </Grid>
      <div className="lg:shadow-xl lg:rounded-xl">
        <Row thin options={['1', '1.000', 'Standaard inbegrepen']} />
        <Row thin options={['1.000', '2.000', '€ 50 p/m']} />
        <Row thin options={['2.000', '5.000', '€ 100 p/m']} />
        <Row thin options={['5.000', '15.000', '€ 200 p/m']} />
        <Row thin options={['15.000', '50.000', '€ 500 p/m']} />
        <Row thin options={['Elke volgende 50.000', '-', '€ 400 p/m']} />
      </div>
    </>
  )
}
