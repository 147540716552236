import Row from './Row'
import GridHead from './GridHead'
import Grid from './Grid'
import ScrollLink from './ScrollLink'

export default function Connect({ refs }) {
  return (
    <>
      <Grid whiteBorder>
        <GridHead label="Stap" className="lg:rounded-tl-xl w-1/3" />
        <GridHead label="Uitvoering" className="lg:rounded-tr-xl w-2/3" />
      </Grid>
      <div className="lg:shadow-xl lg:rounded-xl">
        <Row
          wide
          thin
          label="Aandragen nieuwe handelspartner"
          options="Self service via webportaal"
          description="Betreft het aanleveren van contact gegevens, gewenste berichten"
        />
        <Row
          wide
          thin
          label="Aansluiting koppeling handelspartner"
          options="EDI-Central"
          description="Betreft het opzetten van het onderliggende communicatie protocol"
        />
        <Row
          wide
          thin
          label="Bericht inhoudelijk qualificatie, validatie proces"
          options={
            <>
              Self service. Voor ondersteuning zie{' '}
              <ScrollLink to={refs.support}>support</ScrollLink>.
            </>
          }
        />
      </div>
    </>
  )
}
