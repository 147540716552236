import cn from '../utils/cn'

export default function GridHead({ label, className }) {
  return (
    <div
      className={cn(
        className,
        'text-white bg-gray-500 py-2 lg:py-3 text-center flex-none'
      )}
    >
      <h3 className="font-semibold lg:text-lg">{label}</h3>
    </div>
  )
}
