import Row from './Row'
import MainFeatures from './MainFeatures'
import ScrollLink from './ScrollLink'

export default function Features({ refs }) {
  return (
    <div className="lg:shadow-xl lg:rounded-b-xl lg:rounded-tl-xl">
      <MainFeatures />
      <Row />
      <Row
        label="Alle berichttypes (versturen + ontvangen)"
        options={[true, true, true, true]}
        description="o.a. order, orderbevestiging, pakbon, factuur (BOU, INSBOU, EDIFACT)"
      />
      <Row
        label="Realtime bericht inzage"
        options={[true, true, true, true]}
        description="Inzage ontvangst- en aflevering status berichten via webportaal"
      />
      <Row
        label="Realtime alarmering via email"
        options={[true, true, true, true]}
        description="Melding bij het niet kunnen afleveren, of inhoudelijk foutief bericht"
      />
      <Row
        label="Inbegrepen aantal eigen handelsondernemingen"
        options={['1', '1', '1', '5']}
        description="Gebasseerd op uw KvK inschrijving"
      />
      <Row
        label="Toevoegen extra eigen handelsonderneming"
        options="€ 25 p/m"
        description="Kosten per maand, per handelsonderneming"
      />
      <Row
        thin
        label="Aantal berichten per maand"
        options={
          <>
            Tot en met 1.000 inbegrepen. Zie{' '}
            <ScrollLink to={refs.rates}>staffels</ScrollLink>.
          </>
        }
      />
      <Row
        label="Handelspartners"
        description={
          <>
            Voor toelichting op het aanbod handelspartners, procedures en
            eventuele supportkosten zie{' '}
            <ScrollLink to={refs.connect}>koppelen handelspartners</ScrollLink>.
          </>
        }
      />
      <Row
        indent
        label="Onbeperkt aantal handelspartners"
        options={[true, true, true, true]}
        description="Kies uit het aanbod reeds aangesloten handelspartners, of dien een aanvraag in"
      />
      <Row
        indent
        thin
        label="Handelspartners aanbod"
        options={[
          'Intern',
          'Intern & extern',
          'Intern & extern',
          'Intern & extern',
        ]}
        description={
          <>
            Voor toelichting op het aanbod handelspartners zie{' '}
            <ScrollLink to={refs.connect}>koppelen handelspartners</ScrollLink>.
          </>
        }
      />
      <Row
        thin
        indent
        label="Aansluiting koppeling handelspartner"
        options={
          <>
            Kostenloos, zie{' '}
            <ScrollLink to={refs.connect}>koppelen handelspartners</ScrollLink>.
          </>
        }
      />
      <Row
        thin
        indent
        label="Qualificatie/validatie berichtverkeer"
        options={
          <>
            Op basis van self-service, of support. Zie{' '}
            <ScrollLink to={refs.connect}>koppelen handelspartners</ScrollLink>.
          </>
        }
      />
      <Row
        label="EDI-Central Client"
        description={
          <>
            Voor ondersteuning bij installatie zie{' '}
            <ScrollLink to={refs.support}>support</ScrollLink>.
          </>
        }
      />
      <Row
        indent
        label="Realtime status + alarmering via webportaal"
        options={[true, true, true, true]}
      />
      <Row
        indent
        label="Koppelingsmogelijkheid administratiepakket"
        options={[true, true, true, true]}
        description="Op basis van bestand import/export binnen uw eigen netwerk"
      />
      <Row
        indent
        label="Maatwerk scripts + plugins"
        options={[false, false, true, true]}
        description="Maatwerk bewerking voor versturen/ontvangen lokale systeem"
      />
      <Row label="Uitwisselingsprotocollen" />
      <Row indent label="AS2" options={[false, true, true, true]} />
      <Row
        indent
        label="Ketenstandaard MessageService"
        options={[false, true, true, true]}
        description="Uitwisseling van BOU, INSBOU en SALES berichten via een SOAP interface"
      />
      <Row
        indent
        label="HTTP REST API"
        options={[false, true, true, true]}
        description="Versturen en ontvangen via HTTP REST aanvragen"
      />
      <Row
        indent
        label="FTP(S) extern gehost"
        options={[false, true, true, true]}
        description="Versturen en ontvangen via FTP of FTPS, extern gehost"
      />
      <Row
        indent
        label="FTP(S) hosting"
        options={[false, false, true, true]}
        description="FTP(S) hosting door EDI-Central aangeboden"
      />
      <Row label="Vertalingen" />
      <Row
        indent
        label="Alle beschikbare vertalingen"
        options={[true, true, true, true]}
      />
      <Row
        indent
        label="Maatwerk vertalingen"
        options={[false, false, true, true]}
        description={
          <>
            Voor maatwerk vertaling zie{' '}
            <ScrollLink to={refs.support}>support</ScrollLink>.
          </>
        }
      />
      <Row label="Support (9-17u ma/vr)" />
      <Row
        indent
        label="Product support (responsetijd 1 werkdag)"
        options={[true, true, true, true]}
        description={
          <>
            Afhandeling op{' '}
            <ScrollLink to={refs.support}>supporttarief</ScrollLink>.
          </>
        }
      />
      <Row
        indent
        label="Priority support (responsetijd 3 uur)"
        options={['€ 400 p/m', '€ 400 p/m', '€ 400 p/m', true]}
      />
      <Row
        thin
        indent
        label="Afwijkende afspraken t.a.v. support/beschikbaarheid"
        options="Afwijkende afspraken op aanvraag."
      />
    </div>
  )
}
